<template>
    <div class="main-bg">
        <div v-show="loading" id="semiTransparenDiv"></div>
        <div class="main-bg-wrapper">
            <div class="logo">
                <img :src="require('@/assets/images/logo-img.png')" alt="imageName">
                <img :src="require('@/assets/images/logo-name.png')">
            </div>
            <div class="form-table">
                <div class="form-inner">
                    <p>Reset your Password</p>
                </div>
                <div class="form">
                    <div class="form-group">
                    <label for="exampleInputEmail1">Username</label>
                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Username" v-model="username">
                    <span v-if="username_error" class="error_alert">{{username_error_message}}</span>
                    </div>
                </div>
                <div class="button">
                    <button @click="requestResetPassword()">SEND RESET LINK</button>
                </div>
                <div class="back-to-login">
                    <router-link to="/" replace>BACK TO LOGIN</router-link>
                </div>
            </div>
        </div>
    </div>
  
</template>

<script>
    const axios = require('axios').default;
    const baseUrl =  process.env.VUE_APP_BASE_URL || 'http://192.168.0.159:8080'

    export default {
        name: 'requestResetPassword',
        data () {
            return {
                username:'',
                loading: false,
                errors: [],
                username_error:false,
                username_error_message:"",

            }
        },
        methods: {

            async requestResetPassword(){
                this.errors=[]
                if (this.validate()) {
                    this.loading= true;
                    try{
                        const data =  { "username": this.username };
                        const headers = { 
                            "Content-Type": "application/json",
                        };
                        const response =await  axios.post(`${baseUrl}/auth/request_reset_password`, data,{headers})
                        this.loading = false;
                        // console.log(response);
                        await this.$store.commit("setEmail", response.data['email']);
                        await this.$toasted.success(response.data['detail']);
                        await this.$router.push('/reset-password-blank')
                    }
                    catch (e) {
                        this.loading = false;
                        var i;
                        for (i = 0; i < e.response.data.errors.length; ++i) {
                            this.$toasted.error(e.response.data.errors[i].message)
                        }
                    }

                }

            },

            validate(){
                console.log('validating')
                this.username_error=false
                if ((!this.username) || (this.username.trim().length<=0)) {
                    this.username_error=true
                    this.username_error_message="Please provide username!"
                    this.errors.push("Please provide username!")
                    this.$toasted.error('Please provide username!');
                }

                if (!this.errors.length) {
                    return true;
                } 
            },

            
        },
        mounted(){

        },
        created(){

        },
       
    }

</script>


<style>

</style>